import logo from './logo.png';
import infoicon from './information.png';
import usericon from './user.png';
import questionicon from './question.png';
import fileicon from './file.png';
import spritzenicon from './spritze.png';
import calcicon from './calculator.png';
import formel1 from './1.png'
import formel2 from './2.png'
import formel3 from './3.png'
import formel4 from './4.png'
import formel5 from './5.png'
import formel6 from './6.png'
import formel7 from './7.png'
import formel8 from './8.png'
import formel9 from './9.png'
import formel10 from './10.png'
import formel11 from './11.png'
import formel12 from './12.png'
import formel13 from './13.png'
import formel14 from './14.png'
import formel15 from './15.png'
import formel16 from './16.png'
import formel17 from './17.png'
import formel18 from './18.png'
import formel19 from './19.png'
import formel20 from './20.png'


import { Storage } from '@capacitor/storage';

import { useState, useRef, useEffect } from 'react'
import './App.css';

const SCHRIFTGROSS = 16;
const SCHRIFTMITTEL = 13;
const SCHRIFTKLEIN = 11; // Math.max(window.innerWidth / 100, 10);
const SCHRIFTFARBEHELL = 'white';
const SCHRIFTFARBEDUNKEL = 'black';

const BOXKLEIN = window.innerHeight * 0.08;
const PADDINGKLEIN = BOXKLEIN / 20;
const PADDINGGROSS = BOXKLEIN / 4;
const MARGINKLEIN = '4px'// BOXKLEIN / 16;

const setAgreement = async () => {
  await Storage.set({
    key: 'agreed',
    value: 'yes',
  });
};

const checkAgreement = async () => {
  const { value } = await Storage.get({ key: 'agreed' });
  if (!value) {
    return false
  } else {
    return true
  }
};

function Auswahl({ klein, hoch, titel, optionArray, variable, setVariable }) {

  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    margin: '4px',
    backgroundColor: '#5555',
    borderRadius: 8,
    height: '72px',
    width: klein ? '160px' : '328px',
    justifyContent: 'center',
    alignItems: 'center',
  }}>
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    }}>

      {optionArray.map((option, index) => {
        return <div
          key={'option' + index}
          className={(variable == index) ? 'option-clicked' : 'option'}
          style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', 
            margin: '4px',  
            width: klein ? '44px' : '128px', height: hoch ? '56px' : '28px',
             fontFamily: 'Inter', fontSize: SCHRIFTMITTEL
          }}
          onClick={() => setVariable(index)}>
            <div style={{padding:4}}>
          {option}
          </div>
        </div>
      })}
    </div>
    <div style={{ fontFamily: 'Inter', fontSize: SCHRIFTMITTEL }}>
      {titel}
    </div>

  </div>
}

function Ergebnis({ titel, ergebnis, unit, setVariable, variable }) {
  setVariable && setVariable(ergebnis)
  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    width: '160px',
    height: '72px',
    padding: titel == 'eGFR' ? 0 : PADDINGKLEIN,
    margin: MARGINKLEIN,
    backgroundColor: 'rgba(83, 158, 123, 0.664)',
    borderRadius: 10,
    justifyContent: 'center',

  }}>
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',

    }}>
      <div
        className={'option-clicked'}
        style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center',
          height: titel == 'eGFR' ? '28px' : '56px',
          fontFamily: 'Inter', fontSize: SCHRIFTMITTEL,
          width: '96px',
          padding: titel == 'eGFR' ? 0 : PADDINGGROSS,
          margin: titel == 'eGFR' ? 0 : PADDINGGROSS
        }} >
        {ergebnis} {unit}
      </div>
    </div>
    <div style={{ marginTop: '4px', height: '16px', fontFamily: 'Inter', fontSize: SCHRIFTMITTEL }}>
      {titel}
    </div>

  </div>
}

function ErgebnisZwei({ titel, infos, ergebnisse, units, variable, setVariable }) {
  return <div style={{

    padding: '2vw',
    margin: '2vw',
    backgroundColor: 'rgba(83, 158, 123, 0.664)',
    borderRadius: 10,
  }}>
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    }} >
      {infos.map((info, index) => {
        return <div key={titel + info + index}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start'
          }}>
          <div className={index != 1 ? 'info' : 'infomiddle'} >
            <p style={{ color: '#142D22', fontFamily: 'Inter', fontSize: '90%', fontWeight: index != 1 ? 400 : 600 }}>
              {info}
            </p>
            {ergebnisse[index]} {units[index]}

          </div>
        </div>
      })}
    </div>
    <p style={{ margin: MARGINKLEIN, fontFamily: 'Inter', fontSize: SCHRIFTKLEIN }}>
      {titel}
    </p>

  </div>
}

function Info({ titel, infos, ergebnisse, units, variable, setVariable, zusatzinfos, zusatzergebnisse, zusatzunits, warnungCl, warnungauc }) {
  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    margin: '4px',
    width: '328px',
    height: 30 + (infos.length + (zusatzinfos ? zusatzinfos?.length : 0)) * 28 + 'px',
    marginBottom: MARGINKLEIN,
    backgroundColor: 'rgb(51, 102, 153)',
    borderRadius: 10,
  }}>

    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: MARGINKLEIN,
    }}>
      {infos.map((info, index) => {
        return <div key={titel + info + index}
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '328px',
            height: '24px',
            marginBottom: MARGINKLEIN,
          }}>
          <div className={'info'}
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '307px',
              alignItems: 'center', alignContent: 'center',
              height: '24px',
              color: 'white',
              backgroundColor: ((warnungauc && index == 0) || (warnungCl && index == 1)) ? '#A72134' : 'rgba(122, 122, 122, 0.333)'
            }} >
            <div style={{
              display: 'flex',
              alignItems: 'center', justifyContent: 'end',
              height: '24px', width: '50%', padding: PADDINGKLEIN, paddingRight: '8px',
              color: 'black',
              fontFamily: 'Inter', fontSize: SCHRIFTKLEIN, backgroundColor: 'transparent',
              textAlign: 'right',
              wordBreak: 'normal', whiteSpace: 'pre-wrap'
            }}>
              {info}
            </div>
            <div style={{
              display: 'flex',
              alignItems: 'center', justifyContent: 'start',
              fontFamily: 'Inter', fontSize: SCHRIFTKLEIN, height: '24px',
            }}>
              {ergebnisse[index]} {units[index]}
            </div>

          </div>
        </div>
      })}


      {zusatzinfos?.map((info, index) => {
        return <div key={titel + info + index}
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '328px',
            marginBottom: MARGINKLEIN,
            fontFamily: 'Inter', fontSize: SCHRIFTKLEIN,
          }}>
          <div className={'info'}
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '307px',
              alignItems: 'center', alignContent: 'center',
              height: '24px',
            }} >
            <div style={{
              display: 'flex',
              alignItems: 'center', justifyContent: 'end',
              height: '24px', width: '50%', padding: PADDINGKLEIN, paddingRight: '8px',
              color: 'black', fontFamily: 'Inter', fontSize: SCHRIFTKLEIN,
              textAlign: 'right',
              wordBreak: 'break-word'
            }}>
              {info}
            </div>
            <div style={{
              display: 'flex',
              alignItems: 'center', justifyContent: 'start',
              fontFamily: 'Inter', fontSize: SCHRIFTKLEIN, height: '24px',
            }}>
              {zusatzergebnisse[index]} {zusatzunits[index]}
            </div>

          </div>

        </div>
      })}
    </div>

    <div style={{ height: '16px', fontFamily: 'Inter', fontSize: SCHRIFTMITTEL }}>
      {titel}
    </div>

  </div>
}

function InfoDrei({ titel, infos, ergebnisse, units, variable, setVariable, zusatz, extra }) {
  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    width: '328px',
    height: extra ? '152px' : '112px',
    justifyContent: 'center',
    margin: MARGINKLEIN,
    backgroundColor: 'rgba(83, 158, 123, 0.664)',
    borderRadius: 10,
  }}>

    <div style={{
      display: 'flex',
      marginTop: zusatz ? '7px' : 0,
      height: zusatz ? '13px' : 0,
      marginBottom: zusatz ? MARGINKLEIN : 0,
      flexDirection: 'row',
      justifyContent: 'center',
      color: 'black',
      fontFamily: 'Inter', fontSize: SCHRIFTKLEIN,
    }} >
      {zusatz}
    </div>

    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      height: extra ? '13px' : 0,
      fontFamily: 'Inter', fontSize: SCHRIFTKLEIN,
      color: 'black',
      marginBottom: extra ? 7 : MARGINKLEIN,
    }} >
      {extra}
    </div>

    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    }} >
      {infos.map((info, index) => {
        return <div key={titel + info + index}
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '96px',
            height: extra ? '84px' : '56px',
            fontFamily: 'Inter', fontSize: SCHRIFTKLEIN,
            marginRight: MARGINKLEIN,
            marginLeft: MARGINKLEIN,
            justifyContent: 'flex-start'
          }}>
          <div className={index != 1 ? 'info' : 'infomiddle'}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '96px',
              height: extra ? '84px' : '56px',
              paddingVertical: 0,
            }}>
            <div >
              <div style={{
                display: 'flex', justifyContent: 'center',
                width: '96px',
                padding: 0,
                color: '#142D22',
                fontFamily: 'Inter', fontSize: SCHRIFTKLEIN, fontWeight: index != 1 ? 400 : 600,
                wordBreak: 'break-all'
              }}>
                {info}
              </div>
              <div style={{ marginTop: '6px' }}>
                {ergebnisse[index]}  {units[index]}
              </div>
              <div style={{
                flex: 1, padding: 0,
                height: '50%', marginTop: extra ? 5 : 0, fontFamily: 'Inter', fontSize: SCHRIFTKLEIN
              }}>
                {extra ? Math.round(2.1 * ergebnisse[index] / 100) / 10 + ' ml/h' : null}
              </div>
            </div>
          </div>

        </div>
      })}
    </div>



    <div style={{ margin: MARGINKLEIN, fontFamily: 'Inter', fontSize: SCHRIFTMITTEL, }}>
      {titel}
    </div>

  </div>
}

function InfoDreiNeu({ titel, infos, ergebnisse, units, variable, setVariable, zusatz, extra }) {
  return <div style={{
    display: 'flex',
    flexDirection: 'column',

    width: '328px',
    height: '112px',
    justifyContent: 'center',
    padding: '0px',
    margin: MARGINKLEIN,
    backgroundColor: 'rgba(83, 158, 123, 0.664)',
    borderRadius: 10,
  }}>
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',

      color: 'black'
    }} >
      {zusatz}
    </div>
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      fontFamily: 'Inter', fontSize: SCHRIFTKLEIN,
      color: 'black'
    }} >
      {extra}
    </div>
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    }} >
      {infos.map((info, index) => {
        return <div key={titel + info + index}
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '96px',
            height: '56px',
            justifyContent: 'flex-start',

          }}>
          <div className={'info'}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '96px', height: '56px',
              margin: MARGINKLEIN,
              backgroundColor: (index == 0 && (ergebnisse[index] < 400 || ergebnisse[index] > 600)) ? '#A72134' : 'rgba(122, 122, 122, 0.333)'
            }}>
            <div>
              <div style={{ height: '60%', color: '#142D22', 
                fontFamily: 'Inter', fontSize: SCHRIFTKLEIN / 1, fontWeight: 400, wordBreak: 'break-word' }}>
                {info}
              </div>
              <div style={{ height: '40%', marginTop: extra ? 10 : 0, fontFamily: 'Inter', fontSize: SCHRIFTKLEIN }}>
                {ergebnisse[index]}  {units[index]}
              </div>
              <div style={{ marginTop: extra ? 10 : 0, fontFamily: 'Inter', fontSize: SCHRIFTKLEIN }}>
                {extra ? Math.round(2.1 * ergebnisse[index] / 10) / 100 + ' ml/h' : null}
              </div>
            </div>
          </div>

        </div>
      })}
    </div>



    <p style={{ margin: MARGINKLEIN, fontFamily: 'Inter', fontSize: SCHRIFTKLEIN }}>
      {titel}
    </p>

  </div>
}



function Eingabe({ klein, titel, placeh = false, optionArray, variable, setVariable, setunit, unit,
  faktor, min, max, decimals, language, halb = false }) {
  const inputRef = useRef();
  const buttonRef = useRef();

  const [currunit, setCurrunit] = useState(0)
  const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight })
  /* 
    const resizeHandler = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
  
      setSize({
        width: width,
        height: height,
      });
    };
  
    // Listening for the window resize event
    useEffect(() => {
      window.onresize = resizeHandler;
  
      // You can also use:
      // window.addEventListener('resize', resizeHanlder);
    }, []); */

  const checkRange = (val) => {

    if (val == '' || val < min * faktor[currunit] || val > max * faktor[currunit]) {
      return false
    } else {
      return true
    }


  }

  const handleChange = (e) => {

    let tvalue

    if (decimals) {
      tvalue = e.target.value.replace(/[^0-9,.]/, '')
      console.log('decimals on')
    } else {
      tvalue = parseInt(e.target.value.replace(/\D/, ''))
    }

    if (tvalue) {
      let lt = tvalue.toString().length
      let geteilterString = tvalue.toString().split(".");
      let nachkommstellen = geteilterString[1] ? geteilterString[1].length : 0


      if (lt > 2 && decimals > 0 && nachkommstellen > decimals) {
        tvalue = tvalue.replace(',', '.') //Wichtig, damit Nachkommastellen mitgenommen werden
        tvalue = parseFloat(tvalue).toFixed(decimals ?? 0)
      } else if (!decimals) {
        tvalue = parseFloat(tvalue).toFixed(0)
      }
      setVariable(tvalue)
    } else {
      setVariable('')
    }


    let inrange = checkRange(tvalue)
    inrange ? inputRef.current.style.color = 'white' : inputRef.current.style.color = '#A72134';

    //(decimals > 0 && tvalue.toString().length == 2) ? inputRef.current.style.color = '#A72134':inputRef.current.style.color = 'white';
  }



  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    margin: '4px',
    backgroundColor: '#5555',
    height: '72px',
    borderRadius: 8,
    width: klein ? '160px' : '328px',
    justifyContent: 'center',
  }}>

    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'left',
      marginLeft: klein ? '56px' : '140px',
    }}>

      {optionArray.map((option, index) => {
        return <div
          key={'form' + option + index}
          action={'none'}
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}>
          {/*  <NumPad.Number value={variable?.replace(',', '.') ?? ''}
            placeholder={placeh && !language ? option : null}
            onChange={(e) => { handleChange(e) }}> */}
          <input
            style={{
              display: 'flex',
              height: '22px',
              width: '40px',
              borderWidth: 2,
              borderColor: 'white',
              color: variable != '' || !isNaN(variable) ? 'white' : 'black',
              backgroundColor: checkRange(variable) ? 'rgba(83, 158, 123, 0.664)' : 'white',
              borderRadius: 8,
              fontFamily: 'Inter', fontSize: SCHRIFTMITTEL, textAlign: 'center'
            }}
            ref={inputRef}
            type="tel"
            //pattern="[0-9]+([\,][0-9]+)?"
            type="text" id={"fnameid" + index}
            value={variable?.replace(',', '.') ?? ''}
            onChange={(e) => { handleChange(e) }}
            onBlur={() => { if (!checkRange(variable)) { setVariable(''); } }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                inputRef.current.blur()
              }
            }}
            placeholder={placeh && !language ? option : null}
            name="fname"
          />
          {/*   </NumPad.Number> */}
          <label style={{
            display: 'flex',
            alignSelf: 'center',
            padding: 4,
            margin: 1,
            color: 'white',
            backgroundColor: 'transparent', //'rgba(122, 122, 122, 0.333)',
            borderRadius: 0,
            fontFamily: 'Inter', fontSize: SCHRIFTKLEIN,
          }}
            ref={buttonRef}
            type="text" id="fbutton"
            onClick={(e) => {
              setCurrunit(ou => { if (ou < unit.length - 1) { return ou + 1 } else { return 0 } })
            }}
            name="fbutton"
            value={unit[currunit]}
            placeholder={language ? null : option}>{unit[currunit]}</label>

        </div>
      })}

    </div>

    <label style={{ fontFamily: 'Inter', fontSize: SCHRIFTMITTEL, marginTop: MARGINKLEIN, height: '16px' }}>
      {titel}
    </label>

  </div>
}

function App() {
  const [screen, setScreen] = useState(0)
  const [language, setLanguage] = useState(false)
  const [progress, setProgress] = useState(0)
  const [vanco_bool, setvancoBool] = useState(true)
  const [status, setStatus] = useState('Patientendaten')
  const [warnung, setWarnung] = useState(false)
  const [option, setOption] = useState(false)
  const [disclaimer, setDisclaimer] = useState(false)

  const params = new URLSearchParams(window.location.search);

  const [matches, setMatches] = useState(window.matchMedia("(min-width: 768px)").matches);

  useEffect(() => {
    window.matchMedia("(min-width: 768px)").addEventListener('change',e => setMatches(e.matches));
  }, []);

  const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  const resizeHandler = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    setSize({
      width: width,
      height: height,
    });
  };

  // Listening for the window resize event
  useEffect(() => {
    window.onresize = resizeHandler;

    // You can also use:
    // window.addEventListener('resize', resizeHanlder);
  }, []);

  useEffect(() => {
    if (language && screen == 0) {
      setStatus('Patient data')
    } else if (!language && screen == 0) {
      setStatus('Patientendaten')
    }

  }, [language])

  // EINGABE Patientendaten
  const [gender, setGender] = useState(null)
  const [groesse, setGroesse] = useState(null)
  const [unitGroesse, setUnitGroesse] = useState('cm')
  const [gewicht, setGewicht] = useState(null)
  const [filtrationsleistung, setFiltrationsleistung] = useState(null)
  const [vancospiegel, setVancospiegel] = useState(null)
  const [alter, setAlter] = useState(null)
  const [serumkreatinin, setSerumkreatinin] = useState(null)
  const [eGFR, setEGFR] = useState(null)
  const [medikation, setMedikation] = useState(null)


  useEffect(() => {
    if (params.get("alter")) {
      setAlter(params.get("alter"))
    }
    if (params.get("serumkreatinin")) {
      setSerumkreatinin(params.get("serumkreatinin"))
      setFiltrationsleistung(0)
    }
    if (params.get("egfr")) {
      setEGFR(params.get("egfr"))
      setFiltrationsleistung(1)
    }
    if (params.get("gewicht")) {
      setGewicht(params.get("gewicht"))
    }
    if (params.get("groesse")) {
      setGroesse(params.get("groesse"))
    }
    if (params.get("geschlecht") == 'w') {
      setGender(1)
    }
    if (params.get("geschlecht") == 'm') {
      setGender(0)
    }
  }, [])






  // Populationsdaten
  const [verteilungsvolumen, setVerteilungsvolumen] = useState(0.7)
  const [halbwertszeit, setHalbwertszeit] = useState(7.3)
  const [vancomycinQ0, setVancomycinQ0] = useState(0.13)
  const [vancomycinClearance, setVancomycinClearance] = useState(5.0)

  // Individuelle pharmakokinetische Erwartungswerte
  const [INDverteilungsvolumen, setINDverteilungsvolumen] = useState(null)
  const [vancomycinCl, setvancomycinCl] = useState(null)
  const [vancomycinhalbwertszeit, setvancomycinhalbwertszeit] = useState(null)
  const [initialdosis, setinitialdosis] = useState(null) // INTERMITTIEREND Dosisempfehlung

  useEffect(() => {
    setINDverteilungsvolumen(gewicht * verteilungsvolumen)
  }, [gewicht])

  useEffect(() => {
    let test = (1. - vancomycinQ0) * (eGFR / 100) + vancomycinQ0
    setvancomycinCl(vancomycinClearance * test)
  }, [eGFR])

  useEffect(() => {
    setvancomycinhalbwertszeit((Math.log(2) * INDverteilungsvolumen) / vancomycinCl)
    // INTERMITTIEREND Dosisempfehlung
    setinitialdosis(Math.ceil(((25 * INDverteilungsvolumen) / 500)) * 500)             // [mg] )
  }, [INDverteilungsvolumen, vancomycinCl])




  // EINGABE Intermittierend
  const [dosis, setDosis] = useState(null)
  const [dosisintervall, setDosisintervall] = useState(null)
  const [talspiegel, setTalspiegel] = useState(null)
  const [stundennachgabe, setStundennachGabe] = useState(null)

  const [neueeinzeldosis, setNeueeinzeldosis] = useState(null)
  const [neuesdosisintervall, setNeuesdosisintervall] = useState(null)


  // INTERMITTIEREND Dosisempfehlung

  //let ErhaltungsdosisAUC400 = 0    // [mg/24h]
  //let ErhaltungsdosisAUC600 = 0    // [mg/12h]
  let neueErhaltungsdosis = 0      // [mg/12h]


  // INTERMITTIEREND Individuelle pharmakokinetische Erwartungswerte
  const [eliminationskonstante, seteliminationskonstante] = useState(null)
  const [INDtalspiegel, setINDtalspiegel] = useState(null)
  const [spitzenspiegel, setspitzenspiegel] = useState(null)
  const [INDhalbwertszeit, setINDhalbwertszeit] = useState(null)

  useEffect(() => {
    seteliminationskonstante((-1.) * (Math.log(parseFloat(talspiegel) / (parseFloat(talspiegel) + parseFloat(dosis / (INDverteilungsvolumen)))) / parseFloat(stundennachgabe)))
  }, [talspiegel, dosis, INDverteilungsvolumen, stundennachgabe])

  useEffect(() => {
    setINDtalspiegel(-1 * ((dosis / (INDverteilungsvolumen)) / (1 - Math.exp(eliminationskonstante * dosisintervall))))
    setspitzenspiegel(-1 * ((dosis / (INDverteilungsvolumen)) / (1 - Math.exp(eliminationskonstante * dosisintervall))) * Math.exp(eliminationskonstante * dosisintervall)) //TODO: hier wirklich aus neuer dosis berechnen, wie in Tabelle?
  }, [INDverteilungsvolumen, eliminationskonstante, dosis, dosisintervall])

  useEffect(() => {
    setINDhalbwertszeit(-1 * (0.693 / (-1. * eliminationskonstante)))
  }, [eliminationskonstante])


  const [INDvancomycinClearance, setINDvancomycinClearance] = useState(null)

  useEffect(() => {
    setINDvancomycinClearance((0.693 * INDverteilungsvolumen) / INDhalbwertszeit)
  }, [INDverteilungsvolumen, INDhalbwertszeit])

  const [neuertalspiegel, setneuertalspiegel] = useState(null)
  const [neuerspitzenspiegel, setneuerspitzenspiegel] = useState(null)
  const [neueAUC24h, setneueAUC24h] = useState(null)

  useEffect(() => {
    setneuertalspiegel(neueeinzeldosis ? -1 * ((neueeinzeldosis / (INDverteilungsvolumen)) / (1 - Math.exp(eliminationskonstante * neuesdosisintervall))) : null)
  }, [INDverteilungsvolumen, neueeinzeldosis, eliminationskonstante, neuesdosisintervall])

  useEffect(() => {
    setneuerspitzenspiegel(neuesdosisintervall ? neuertalspiegel * Math.exp(eliminationskonstante * neuesdosisintervall) : null)
  }, [INDverteilungsvolumen, neuesdosisintervall, neuertalspiegel, eliminationskonstante])

  useEffect(() => {
    setneueAUC24h(neueeinzeldosis ? ((neueeinzeldosis * 24) / neuesdosisintervall) / INDvancomycinClearance : null)
  }, [INDverteilungsvolumen, neueeinzeldosis, neuesdosisintervall, INDvancomycinClearance])






  // EINGABE Intermittierend
  const [serumspiegel, setSerumspiegel] = useState(null)
  const [dosrate, setDosrate] = useState(null)
  const [infusionsdosis, setInfusionsdosis] = useState(null)
  const [laufrate, setLaufrate] = useState(null)
  const [infusionsrate, setInfusionsrate] = useState(null)


  // DAUERINFUSION Individuelle pharmakokinetische Parameter
  const [INDvancomycinClearanceDauerinfusion, setINDvancomycinClearanceDauerinfusion] = useState(null)

  useEffect(() => {
    if (infusionsdosis && serumspiegel) {
      setINDvancomycinClearanceDauerinfusion(((infusionsdosis / 24) / serumspiegel))
      //alert(Math.round(((infusionsdosis / 24) / serumspiegel) * 10) / 10+ ' ' +  vancomycinCl*0.75 + ' ' + vancomycinCl*1.5)
    }
    console.debug('INDvancomycinClearanceDauerinfusion', INDvancomycinClearanceDauerinfusion)
  }, [infusionsdosis, serumspiegel])



  console.log('render')
  useEffect(() => {
    medikation == 0 && vancospiegel == 0 && language && setStatus(['Adjusted dosing', <br />, 'intermittent infusion'])
    medikation == 0 && vancospiegel == 0 && !language && setStatus('Dosisanpassung intermittierende Gabe')
    medikation == 1 && vancospiegel == 0 && language && setStatus(['Adjusted dosing', <br />, 'continuous infusion'])
    medikation == 1 && vancospiegel == 0 && !language && setStatus('Dosisanpassung Dauerinfusion')
  }, [medikation])

  useEffect(() => {
    vancospiegel == 1 && language && setStatus('Empiric dosing')
    vancospiegel == 1 && !language && setStatus('Empirische Dosierung')
    vancospiegel == 0 && language && setStatus('New dose')
    vancospiegel == 0 && !language && setStatus('Dosisanpassung')
    medikation == 0 && vancospiegel == 0 && language && setStatus(['Adjusted dosing', <br />, 'intermittent infusion'])
    medikation == 0 && vancospiegel == 0 && !language && setStatus('Dosisanpassung intermittierende Gabe')
    medikation == 1 && vancospiegel == 0 && language && setStatus(['Adjusted dosing', <br />, 'continuous infusion'])
    medikation == 1 && vancospiegel == 0 && !language && setStatus('Dosisanpassung Dauerinfusion')
  }, [vancospiegel])

  useEffect(() => {
    if (screen == 3 && INDvancomycinClearance && dosis && dosisintervall) {
      if ((INDvancomycinClearance < vancomycinCl * 0.75) || (INDvancomycinClearance > vancomycinCl * 1.5)) {
        setWarnung(true)
      } else if ((((dosis * 24 / dosisintervall) / INDvancomycinClearance) < 400) || ((dosis * 24 / dosisintervall) / INDvancomycinClearance) > 600) {
        setWarnung(true)
      } else {
        setWarnung(false)
      }
    }
    if (screen == 0) {
      language ? setStatus('Patient data') : setStatus("Patientendaten")
    } else if (screen == 1) {
      !vancospiegel && language ? setStatus('Dosage') : setStatus('Dosierung')
      vancospiegel == 1 && language && setStatus('Empiric dosing')
      vancospiegel == 1 && !language && setStatus('Empirische Dosierung')
      vancospiegel == 0 && language && setStatus('New dose')
      vancospiegel == 0 && !language && setStatus('Dosisanpassung')
      medikation == 0 && vancospiegel == 0 && language && setStatus(['Adjusted dosing', <br />, 'intermittent infusion'])
      medikation == 0 && vancospiegel == 0 && !language && setStatus('Dosisanpassung intermittierende Gabe')
      medikation == 1 && vancospiegel == 0 && language && setStatus(['Adjusted dosing', <br />, 'continuous infusion'])
      medikation == 1 && vancospiegel == 0 && !language && setStatus('Dosisanpassung Dauerinfusion')
    } else if (screen == 3) {
      language ? setStatus('PK data') : setStatus('PK Daten')
    } else if (screen == 4) {
      language ? setStatus('About') : setStatus('Info')
    }
  }, [screen])




  useEffect(() => {
    if ((INDvancomycinClearance < vancomycinCl * 0.75) || (INDvancomycinClearance > vancomycinCl * 1.5)) {
      setWarnung(true)
    } else if ((((dosis * 24 / dosisintervall) / INDvancomycinClearance) < 400) || ((dosis * 24 / dosisintervall) / INDvancomycinClearance) > 600) {
      setWarnung(true)
    } else {
      setWarnung(false)
    }
  }, [INDvancomycinClearance])

  useEffect(() => {
    //alert(INDvancomycinClearanceDauerinfusion < vancomycinCl * 0.75)
    let auc = infusionsdosis / INDvancomycinClearanceDauerinfusion
    if ((auc < 400) || (auc > 600) ||
      ((INDvancomycinClearanceDauerinfusion < vancomycinCl * 0.75) ||
        (INDvancomycinClearanceDauerinfusion > vancomycinCl * 1.5))
    ) {
      setWarnung(true)
    } else {
      setWarnung(false)
    }
  }, [infusionsdosis, INDvancomycinClearanceDauerinfusion])


  useEffect(() => {
    if (dosrate == 0) {
      setLaufrate(computeLaufrate(infusionsdosis))
    }
  }, [infusionsdosis])


  useEffect(() => {
    if (dosrate == 1) {
      setInfusionsdosis(computeDosis())
    }
  }, [laufrate])

  const computeEGFR = () => {

    let result = null
    let _serumkreatinin = language ? (serumkreatinin / 88.4) : serumkreatinin

    if (gender == 0) {
      result = ((140 - alter) * (50 + ((language ? (groesse * 2.54) : groesse) - 152.4) * 0.89)) / (72 * _serumkreatinin)         //Männer: (140-Alter)*(50+(Größe-152,4)*0,89)/(72*Serum-Kreatinin)
    } else {
      result = (0.85 * (140 - alter) * (45.5 + ((language ? (groesse * 2.54) : groesse) - 152.4) * 0.89)) / (72 * _serumkreatinin)  //Frauen: 0,85*(140-Alter)*(45,5+(Größe-152,4)*0,89)/(72*Serum-Kreatinin)
    }
    result = (Math.round(result * 100) / 100).toFixed(1);
    return result
  }

  const computeLaufrate = (dosis) => {
    let result = null
    result = dosis * 2.1 / 1000
    result = (Math.round(result * 10) / 10).toString();
    return result
  }

  const computeDosis = () => {
    let result = null
    result = laufrate * 1000 / 2.1
    result = (Math.round(result / 100) * 100).toFixed(0);
    return result
  }

  const computeNeuerTalspiegel = () => {
    let result = null
    result = -1. * ((neueeinzeldosis / (INDverteilungsvolumen)) / (1. - Math.exp(eliminationskonstante * neuesdosisintervall)))
    result = (Math.round(result * 100) / 100).toFixed(1);
    console.log(eliminationskonstante)
    return result
  }

  const computeNeuerSpitzenspiegel = () => {
    let result = null
    result = -1. * ((neueeinzeldosis / (INDverteilungsvolumen)) / (1. - Math.exp(eliminationskonstante * neuesdosisintervall))) * Math.exp(eliminationskonstante * neuesdosisintervall)
    result = (Math.round(result * 100) / 100).toFixed(1);
    return result
  }

  const computeNeueAUC24 = () => {
    let result = null
    result = ((neueeinzeldosis * 24) / neuesdosisintervall) / INDvancomycinClearance
    result = (Math.round(result * 100) / 100).toFixed(0);
    return result
  }

  const computeNeueAUC24Dauerinfusion = () => {
    let result = null
    result = infusionsdosis / INDvancomycinClearanceDauerinfusion
    result = (Math.round(result / 1) * 1).toFixed(0);
    return result
  }

  const computeNeueAUC400 = (vanco_cl) => {
    /* =WENN(($H$9*400)<400;"500mg/36h";
 WENN(($H$9*400)<600;"500mg/24h";
 WENN(($H$9*400)<800;"750mg/24h";
 WENN(($H$9*400)<1100;"1000mg/24h";
 WENN(($H$9*400)<1300;"1250mg/24h";
 WENN(($H$9*400)<1600;"750mg/12h";
 WENN(($H$9*400)<1800;"1000mg/18h";
 WENN(($H$9*400)<2200;"1000mg/12h";
 WENN(($H$9*400)<2600;"1250mg/12h";
 WENN(($H$9*400)<3300;"1500mg/12h";
 WENN(($H$9*400)<4400;"1000mg/6h";"1500mg/8h")))))))))))*/
    let hilf = vanco_cl * 400
    let result = null

    if (hilf < 400) {
      result = "500 mg/36h"
    } else if (hilf < 600) {
      result = "500 mg/24h"
    } else if (hilf < 800) {
      result = "750 mg/24h"
    } else if (hilf < 1100) {
      result = "1000 mg/24h"
    } else if (hilf < 1300) {
      result = "1250 mg/24h"
    } else if (hilf < 1600) {
      result = "750 mg/12h"
    } else if (hilf < 1700) {
      result = "1000 mg/18h"
    } else if (hilf < 2200) {
      result = "1000 mg/12h"
    } else if (hilf < 2600) {
      result = "1250 mg/12h"
    } else if (hilf < 3300) {
      result = "1500 mg/12h"
    } else if (hilf < 4400) {
      result = "1000 mg/6h"
    } else {
      result = "1500 mg/8h"
    }

    return result
  }

  const computeNeueAUC600 = (vanco_cl) => {
    /* =WENN(($H$9*600)<400;"500mg/36h";
    WENN(($H$9*600)<600;"500mg/24h";
    WENN((H9*500)<800;"750mg/24h"; //TODO: warum hier 500?
    WENN(($H$9*600)<1100;"1000mg/24h";
    WENN(($H$9*600)<1300;"1250mg/24h";
    WENN(($H$9*600)<1600;"750mg/12h";
    WENN(($H$9*600)<1800;"1000mg/18h";
    WENN(($H$9*600)<2200;"1000mg/12h";
    WENN(($H$9*600)<2600;"1250mg/12h";
    WENN(($H$9*600)<3300;"1500mg/12h";
    WENN(($H$9*600)<4400;"1000mg/6h";"1500mg/8h")))))))))))*/
    let hilf = vanco_cl * 600
    let result = null

    if (hilf < 400) {
      result = "500 mg/36h"
    } else if (hilf < 600) {
      result = "500 mg/24h"
    } else if ((hilf / 600) * 500 < 800) {
      result = "750 mg/24h"
    } else if (hilf < 1100) {
      result = "1000 mg/24h"
    } else if (hilf < 1300) {
      result = "1250 mg/24h"
    } else if (hilf < 1600) {
      result = "750 mg/12h"
    } else if (hilf < 1700) {
      result = "1000 mg/18h"
    } else if (hilf < 2200) {
      result = "1000 mg/12h"
    } else if (hilf < 2600) {
      result = "1250 mg/12h"
    } else if (hilf < 3300) {
      result = "1500 mg/12h"
    } else if (hilf < 4400) {
      result = "1000 mg/6h"
    } else {
      result = "1500 mg/8h"
    }

    return result
  }


  const computeNeueErhaltungsdosis = (vanco_cl) => {
    /*   =WENN(($H$9*500)<400;"500mg/36h";
         WENN(($H$9*500)<600;"500mg/24h";
         WENN(($H$9*500)<800;"750mg/24h";
         WENN(($H$9*500)<1100;"1000mg/24h";
         WENN(($H$9*500)<1300;"1250mg/24h";
         WENN(($H$9*500)<1600;"750mg/12h";
         WENN(($H$9*500)<1800;"1000mg/18h";
         WENN(($H$9*500)<2200;"1000mg/12h";
         WENN(($H$9*500)<2600;"1250mg/12h";
         WENN(($H$9*500)<3300;"1500mg/12h";
         WENN(($H$9*500)<4400;"1000mg/6h";"1500mg/8h"))))))))))) */
    let hilf = vanco_cl * 500 //woher kommt 500?
    let result = null

    if (hilf < 400) {
      result = "500 mg/36h"
    } else if (hilf < 600) {
      result = "500 mg/24h"
    } else if (hilf < 800) {
      result = "750 mg/24h"
    } else if (hilf < 1100) {
      result = "1000 mg/24h"
    } else if (hilf < 1300) {
      result = "1250 mg/24h"
    } else if (hilf < 1600) {
      result = "750 mg/12h"
    } else if (hilf < 1700) {
      result = "1000 mg/18h"
    } else if (hilf < 2200) {
      result = "1000 mg/12h"
    } else if (hilf < 2600) {
      result = "1250 mg/12h"
    } else if (hilf < 3300) {
      result = "1500 mg/12h"
    } else if (hilf < 4400) {
      result = "1000 mg/6h"
    } else {
      result = "1500 mg/8h"
    }

    return result
  }


  const computeErhaltungsdosis = (vanco_cl) => {
    let result = null
    result = vanco_cl * 500
    result = (Math.round(result * 100) / 100).toFixed(0); //TODO: Runden(;-2)
    return result
  }

  const computeAUC400 = (vanco_cl) => {
    let result = null
    result = vanco_cl * 400
    result = (Math.round(result * 100) / 100).toFixed(0); //TODO: Runden(;-2)
    return result
  }

  const computeAUC600 = (vanco_cl) => {
    let result = null
    result = vanco_cl * 600
    result = (Math.round(result * 100) / 100).toFixed(0); //TODO: Runden(;-2)
    return result
  }

  useEffect(async () => {
    let agreed = await checkAgreement()
    if (agreed == true) {
      setDisclaimer(true)
    } else {
      setDisclaimer(false)
    }
  }, [])

  let patientdata = (gender != null) && groesse && alter && gewicht && eGFR

  return (
    <div className="App" style={{overflowY: 'scroll'}} >
       {/* ‚ */}
      <header className="App-header" style={{ display: 'flex', width: '100%',minHeight:80, flexDirection: 'row',  }}>
        {/*       <img src={logo} className="App-logo" alt="logo" />  */}
        <div style={{ display: 'flex', marginTop: 0.05, padding: PADDINGKLEIN,  height:80, 
        justifyContent: 'center', alignItems: 'center',
        color: 'whitesmoke', fontFamily: 'Inter', fontSize: SCHRIFTGROSS / 0.9 }}>
          <div style={{ display: 'flex', color: 'rgb(105, 177, 199)', fontSize: 16, fontWeight:900 }}>
            {language ? ['Vancomycin dosage and', <br />, ' therapeutic drug monitoring in steady-state'] : ['Vancomycin i.v. Dosierung und', <br />, 'Therapeutisches Drug Monitoring im Steady State']}
          </div>
        </div>
      </header>

      <div style={{
        display: 'flex', width: '100%',
        flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
      }}>
        <div style={{
          display: 'flex',
          width: '33%', height: 50, justifyContent: "center",
          alignItems: "center",
          borderRadius: 12, marginLeft: -10, 
          padding: 0, color: 'black',
          backgroundColor: 'rgb(105, 177, 199)'
        }}>
          <div style={{ marginLeft: 10 }}>
            {status}
          </div>
        </div>

      </div>


      <div style={{
        display: 'flex', flexDirection: matches?'row':'column',
        alignItems: matches?'normal': 'center',
        /* height: (size.height - size.height * 0.2),
        overflowY: 'scroll', */
      }}>

        {!disclaimer &&
          <div style={{
            backgroundColor: '#282c34',
            fontFamily: 'Inter', fontSize: SCHRIFTGROSS,
            color: 'white',
            padding: '2%',
          }}>
            VancoEasy wurde nur für den persönlichen Gebrauch erstellt. Die Verwendung eines von VancoEasy erzeugten Ergebnisses erfolgt in jedem Fall auf alleiniges Risiko und Verantwortung des VancoEasy-Benutzers. Therapeutische Entscheidungen sollten nicht ausschließlich auf VancoEasy beruhen, da die von VancoEasy bereitgestellten Informationen die klinische Beurteilung nicht ersetzen.  VancoEasy-Benutzer sollten zusätzlich zur Verwendung dieser App und vor medizinischen Entscheidungen einen Arzt konsultieren. Obwohl VancoEasy sorgfältig validiert wurde, gibt es keine Garantie für die Richtigkeit der bereitgestellten Ergebnisse. Diese App wird bereitgestellt von Dr. Ute Chiriac, Universitätsklinikum Heidelberg & Dr. Otto Frey, Klinikum Heidenheim. Bevor Sie fortfahren, vergewissern Sie sich bitte, dass Sie den Haftungsausschluss gelesen und verstanden haben.
            <div style={{ display: 'flex', justifyContent: 'center', }}>
              <div
                className='weiterbutton'

                onClick={() => {
                  setAgreement()
                  setDisclaimer(true)
                }}>
                {language ? 'Accept' : 'Akzeptieren'}
              </div>
            </div>


          </div>}

        <div style={{
          display: 'flex',
          width: 80, 
          flexDirection: matches?'column':'row',
          position: matches?'relative':'fixed',
          bottom: matches?0:0,
          fontFamily: 'Inter', fontSize: SCHRIFTGROSS,
          color: 'white',
          justifyContent:'center'
        }}>
          <a className='nav' style={{
            padding: '20%', borderLeftRadius: 4, 
            fontFamily: 'Inter', fontSize: SCHRIFTKLEIN, textDecoration: 'none', color: 'white',
            backgroundColor:  '#282c34',
          }}
          //onClick={() => setApp(false)}
              href="https://www.doseeasy.de"
              target='_self'
              rel="noopener"
          >
            <img src={spritzenicon} style={{ width: '35px', aspectRatio: 1, }} alt="spritzenlogo" />
            <div style={{ width: '10%', fontFamily: 'Inter', fontSize: SCHRIFTKLEIN, width: '100%' }}> Arzneistoff</div>
          </a>
          <div className='nav' style={{ padding: '20%', borderLeftRadius: 4, backgroundColor: screen == 0 ? 'rgb(105, 177, 199)' : '#282c34', fontFamily: 'Inter', fontSize: '70%' }} onClick={() => { setScreen(0) }}>
            <img src={usericon} style={{ width: '100%', aspectRatio: 1 }} alt="logo" />
            <div style={{ width: '10%', fontFamily: 'Inter', fontSize: SCHRIFTKLEIN, width: '100%' }}> Patient</div>
          </div>
          <div className='nav' style={{ padding: '20%', backgroundColor: screen == 1 || screen == 2 ? 'rgb(105, 177, 199)' : '#282c34' }}
            onClick={() => {
              if (medikation != null && vancospiegel == 0) {
                medikation == 0 && vancospiegel == 0 && language && setStatus(['Adjusted dosing', <br />, 'intermittent infusion'])
                medikation == 0 && vancospiegel == 0 && !language && setStatus('Dosisanpassung intermittierende Gabe')
                medikation == 1 && vancospiegel == 0 && language && setStatus(['Adjusted dosing', <br />, 'continuous infusion'])
                medikation == 1 && vancospiegel == 0 && !language && setStatus('Dosisanpassung Dauerinfusion')
                setScreen(2)
              }
              else if (patientdata) {
                setScreen(1)
              }
            }}>
            <img src={calcicon} style={{ width: '100%', aspectRatio: 1 }} alt="calc logo" />
            <div style={{ width: '10%', fontFamily: 'Inter', fontSize: SCHRIFTKLEIN, width: '100%' }}> {language ? 'Calculator' : 'Rechner'}</div>
          </div>
          {/*         <div className='nav' style={{ padding: '20%', flex: 1, backgroundColor: screen == 3 ? 'rgb(105, 177, 199)' : '#282c34' }} onClick={() => { setScreen(3) }}>
          <img src={fileicon} style={{ margin: MARGINKLEIN,width: '100%', aspectRatio:1 }} alt="logo" />
          <div style={{ width: '10%', fontFamily:'Inter', fontSize: '70%',width:'100%'  }}>  {language ? 'PK Data' : 'PK Daten'}</div>
          </div> */}
          <div className='nav' style={{ padding: '20%', borderRightRadius: 4, backgroundColor: screen == 4 ? 'rgb(105, 177, 199)' : '#282c34' }} onClick={() => { setScreen(4) }}>
            <img src={infoicon} style={{ width: '100%', aspectRatio: 1 }} alt="logo" />
            <div style={{ width: '10%', fontFamily: 'Inter', fontSize: SCHRIFTKLEIN, width: '100%' }}>  {language ? 'About' : 'Hinweise'}</div></div>
          <div
            className='langbutton'
            style={{ margin: '5%', fontFamily: 'Inter', fontSize: 16, justifyContent: 'center', alignSelf: 'center' }}
            onClick={() => {
              setLanguage(!language)
              setWarnung(false)
              setGewicht(null)
              setGroesse(null)
              setUnitGroesse('cm')
              setGender(null)
              setFiltrationsleistung(null)
              setVancospiegel(null)
              setAlter(null)
              setSerumkreatinin(null)
              setEGFR(null)
              setMedikation(null)
            }}> {language ? (matches?'DEUTSCH':'DE'): (matches? 'ENGLISH':'EN')} </div>

        </div>



        {((screen != 4 && matches) || ( !matches && screen <1)         ) && disclaimer &&
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '336px',
            marginTop: '8px',
            fontFamily: 'Inter', fontSize: SCHRIFTGROSS,
            color: 'white',
          }}>

            <div style={{ display: 'flex', flexDirection: 'row', }}>
              <Auswahl klein titel={language ? 'Gender' : 'Geschlecht'} optionArray={['m', language ? 'f' : 'w']} setVariable={setGender} variable={gender}></Auswahl>
              <Eingabe klein language={language} titel={language ? 'Age' : 'Alter'} halb={true} optionArray={['40']} setVariable={setAlter} variable={alter} unit={[language ? 'years' : 'Jahre']} min={16} max={110} faktor={[1]}></Eingabe>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Eingabe klein language={language} titel={language ? 'Height' : 'Größe'} halb={true} decimals={language ? 2 : null} optionArray={['170']} setVariable={setGroesse} variable={groesse} min={language ? 120 / 2.54 : 120} max={language ? 220 / 2.54 : 220} setunit={setUnitGroesse} unit={[language ? 'inch' : 'cm']} faktor={[1]}></Eingabe>
              <Eingabe klein language={language} titel={language ? 'Body weight' : 'Gewicht'} halb={true} optionArray={['80']} setVariable={setGewicht} variable={gewicht} min={30} max={200} unit={['kg']} faktor={[1]}></Eingabe>
            </div>
            <Auswahl titel={language ? 'Renal function' : 'Filtrationsleistung'} optionArray={[language ? 'Serumcreatinine' : 'Serumkreatinin', 'eGFR']} setVariable={setFiltrationsleistung} variable={filtrationsleistung}></Auswahl>

            <div style={{ display: 'flex', flexDirection: 'row' }}>

              {filtrationsleistung == 0 &&
                <Eingabe klein language={language} titel={language ? 'Serumcreatinine' : 'Serumkreatinin'} halb={true} optionArray={language ? ['50'] : ['5']} setVariable={setSerumkreatinin} variable={serumkreatinin} decimals={1} min={language ? 44 : 0.5} max={language ? 704 : 8} unit={[language ? 'µmol/l' : 'mg/dl']} faktor={[1, 88.4]} > </Eingabe>}
              {filtrationsleistung == 1 &&
                <Eingabe klein language={language} titel={'eGFR'} optionArray={['100']} halb={true} setVariable={setEGFR} variable={eGFR} decimals={1} min={eGFR ? 0 : 1} max={250} unit={['ml/min']} faktor={[1]} > </Eingabe>}

              <div style={{ width: '50%' }}>
                {filtrationsleistung == 0 && gender != null && alter && groesse && serumkreatinin && serumkreatinin > 0 &&
                  ((!language && serumkreatinin.toString().length != 2) || (language && serumkreatinin < 705)) &&

                  <Ergebnis titel={'eGFR'} ergebnis={computeEGFR()} unit={'ml/min'} setVariable={setEGFR} variable={eGFR}></Ergebnis>}
              </div>
            </div>

            <div style={{ display: 'flex',width: patientdata?'100%':'50%', flexDirection: 'row', justifyContent: 'space-evenly' }}>
              <div
                className='resetbutton'

                onClick={() => {
                  setWarnung(false)
                  setGewicht(null)
                  setGroesse(null)
                  setUnitGroesse('cm')
                  setGender(null)
                  setFiltrationsleistung(null)
                  setVancospiegel(null)
                  setAlter(null)
                  setSerumkreatinin(null)
                  setEGFR(null)
                  setMedikation(null)

                  setDosis(null)
                  setDosisintervall(null)
                  setTalspiegel(null)
                  setStundennachGabe(null)

                  setNeueeinzeldosis(null)
                  setNeuesdosisintervall(null)

                  setSerumspiegel(null)
                  setDosrate(null)
                  setInfusionsdosis(null)
                  setLaufrate(null)
                  setInfusionsrate(null)
                }}> Reset </div>
              {filtrationsleistung != null && gender != null && alter && groesse && gewicht && (serumkreatinin || eGFR) &&
                <div
                  className='weiterbutton'

                  onClick={() => {
                    setScreen(1)
                  }}> {language ? 'Next' : 'Weiter'} </div>}
            </div>



          </div>
        }


        {(screen === 1) && disclaimer &&
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '336px',
            marginTop: '8px',
            backgroundColor: '#282c34',
            fontFamily: 'Inter', fontSize: SCHRIFTGROSS,
            color: 'white',
          }}>


            <Auswahl titel={language ? 'Vancomycin blood level' : 'Vancomycinspiegel'} optionArray={[language ? 'Yes' : 'Ja', language ? 'No' : 'Nein']} setVariable={setVancospiegel} variable={vancospiegel}></Auswahl>
            {vancospiegel == 0 &&
              <Auswahl hoch titel={''} optionArray={[language ? 'Intermittent infusion' : 'Intermittierende Gabe', language ? 'Continuous infusion' : 'Dauerinfusion']} setVariable={setMedikation} variable={medikation}></Auswahl>}

            {vancospiegel == 1 &&
              <InfoDrei titel={language ? 'Intermittent infusion' : 'Intermittierende Gabe - empirisch'}
                infos={['AUC 400', language ? 'Dose' : 'Dosis', 'AUC 600']}
                ergebnisse={[computeNeueAUC400(vancomycinCl), computeNeueErhaltungsdosis(vancomycinCl), computeNeueAUC600(vancomycinCl)]}
                units={['', '']}
                zusatz={language ? ('Loading dose ' + initialdosis + ' mg, Infusion time ' + Math.round(initialdosis / 100) * 100 / 1000 + ' h') : ('Initialdosis ' + initialdosis + ' mg, Infusionsdauer ' + Math.round(initialdosis / 100) * 100 / 1000 + ' h')} />}

            {vancospiegel == 1 &&
              <InfoDrei titel={language ? 'Continuous infusion' : 'Dauerinfusion - empirisch'}
                infos={['AUC 400', language ? 'Dose' : 'Dosis', 'AUC 600']}
                ergebnisse={[Math.round((vancomycinCl * 400) / 100) * 100, computeErhaltungsdosis(vancomycinCl), Math.round((vancomycinCl * 600) / 100) * 100]}
                units={['mg/24h', 'mg/24h', 'mg/24h', 'mg/24h']}
                zusatz={language ? ('Loading dose ' + Math.round(initialdosis / 100) * 100 + ' mg, infusion time ' + Math.round(initialdosis / 100) * 100 / 1000 + ' h') : ('Initialdosis ' + Math.round(initialdosis / 100) * 100 + ' mg, Infusionsdauer ' + Math.round(initialdosis / 100) * 100 / 1000 + ' h')}
                extra={language ? ('Standard perfusor (1g/50ml):' + ' ' + 50 * initialdosis / 1000 + ' ml, ' + 'infusion rate ' + (50 * initialdosis / 1000) / (Math.round(initialdosis / 100) * 100 / 1000) + ' ml/h') : ('Standardperfusor (1g/50ml):' + ' ' + 50 * initialdosis / 1000 + ' ml, ' + 'Laufrate ' + (50 * initialdosis / 1000) / (Math.round(initialdosis / 100) * 100 / 1000) + ' ml/h')} />}

            {medikation == null || !(serumkreatinin || eGFR) || vancospiegel == 1 &&
              <div style={{ display: 'flex', width: '50%', flexDirection: 'row', justifyContent: 'space-evenly' }}>

                <div
                  className='weiterbutton'
                  onClick={() => {
                    setScreen(0)
                  }}> {language ? 'Back' : 'Zurück'}
                </div>

              </div>
            }

            {medikation != null && (serumkreatinin || eGFR) && vancospiegel == 0 &&
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>

                <div
                  className='weiterbutton'
                  onClick={() => {
                    setScreen(0)
                  }}> {language ? 'Back' : 'Zurück'}
                </div>
                <div
                  className='weiterbutton'
                  onClick={() => {
                    setScreen(oldscreen => oldscreen + 1)
                  }}> {language ? 'Next' : 'Weiter'}
                </div>
              </div>
            }





          </div>
        }

        {screen == 1 && vancospiegel == 1 &&
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '336px',
            marginTop: '8px',
            backgroundColor: '#282c34',
            fontFamily: 'Inter', fontSize: SCHRIFTGROSS,
            color: 'white',

          }}>

            <Info titel={language ? 'Population data' : 'Populationsparameter'}
              infos={[language ? 'Volume of distribution ' : 'Verteilungsvolumen', language ? 'Half-life ' : 'Halbwertszeit', 'Vancomycin Cl']}
              ergebnisse={[verteilungsvolumen, halbwertszeit, vancomycinClearance]}
              units={['l/kg', 'h', 'l/h']} ></Info>


            <Info titel={language ? 'Estimated individual pharmacokinetic parameters' : 'Individuelle kinetische Erwartungswerte'}
              infos={[language ? 'Volume of distribution ' : 'Verteilungsvolumen', 'Vancomycin Cl', language ? 'Half-life ' : 'Halbwertszeit']}
              ergebnisse={[Math.round(INDverteilungsvolumen * 10) / 10, Math.round(vancomycinCl * 10) / 10, Math.round(vancomycinhalbwertszeit * 10) / 10]}
              units={['l', 'l/h', 'h']} ></Info>

          </div>
        }



        {(screen === 2) && medikation == 0 && gender != null && gewicht && alter && groesse &&
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '336px',
            marginTop: '8px',
            backgroundColor: '#282c34',
            fontFamily: 'Inter', fontSize: SCHRIFTGROSS,
            color: 'white',
          }}>


            <Eingabe titel={language ? 'Dose' : 'Dosis'} optionArray={['250-2000']} setVariable={setDosis}
              variable={dosis} min={250} max={2000} unit={['mg']} faktor={[1]}></Eingabe>
            <Eingabe titel={language ? 'Interval' : 'Dosisintervall'} optionArray={['6-48']} setVariable={setDosisintervall}
              variable={dosisintervall} min={6} max={48} unit={['h']} faktor={[1]}></Eingabe>
            <Eingabe titel={language ? 'Trough level' : 'Talspiegel'} optionArray={['4-60']} decimals={1} setVariable={setTalspiegel}
              variable={talspiegel} unit={['mg/l']} min={4} max={60} faktor={[1]}></Eingabe>
            <Eingabe titel={language ? 'Time from start of dose to level' : 'Stunden nach Gabe'} decimals={1} optionArray={['']} setVariable={setStundennachGabe}
              variable={stundennachgabe} min={dosisintervall ? dosisintervall - 2 : 1} max={2 + parseInt(dosisintervall)}
              setunit={setUnitGroesse} unit={['h']} faktor={[1]}></Eingabe>

            {warnung && //talspiegel && stundennachgabe && dosis && dosisintervall &&

              <div style={{
                //position: 'absolute',  
                display: 'flex', flexDirection: 'column',
                alignItems: 'center',
               
                padding: PADDINGGROSS,
                margin: MARGINKLEIN,
                borderRadius: 10,
                color: 'white', backgroundColor: '#A72134'
              }}>
                <h2>
                  {language ? 'WARNING' : 'WARNHINWEIS'}
                </h2>

                {((((dosis * 24 / dosisintervall) / INDvancomycinClearance) < 400) || ((dosis * 24 / dosisintervall) / INDvancomycinClearance) > 600) &&
                  <p> {language ? 'Dosing outside target range' : 'AUC nicht im angestrebten Bereich'} </p>
                }

                {((INDvancomycinClearance < vancomycinCl * 0.75) || (INDvancomycinClearance > vancomycinCl * 1.5)) &&
                  <div style={{ fontFamily: 'Inter', fontSize: SCHRIFTKLEIN }}>
                    <p>{language ? 'Dose administered?' : 'Dosis gegeben?'}</p>
                    <p>{language ? 'Dose omitted?' : 'Dosis vergessen?'}</p>
                    <p>{language ? 'Time of blood sampling?' : 'Zeitpunkt der Blutabnahme?'}</p>
                    <p>{language ? 'Sample take from access?' : 'Abnahme aus Zugang?'}</p>
                    <p>{language ? 'Right patient?' : 'Patient verwechselt?'}</p>
                  </div>
                }





                <div
                  className='weiterbutton'
                  onClick={() => {
                    setWarnung(false)
                  }}> OK </div>


              </div>}

            {talspiegel && stundennachgabe && dosis && dosisintervall &&
              <InfoDrei titel={language ? 'New dose' : 'Neue Dosierung'}
                infos={['AUC 400', language ? 'Dose' : 'Dosis', 'AUC 600']}
                ergebnisse={[computeNeueAUC400(INDvancomycinClearance), computeNeueErhaltungsdosis(INDvancomycinClearance), computeNeueAUC600(INDvancomycinClearance)]}
                units={['', '']} ></InfoDrei>}

            {talspiegel && stundennachgabe &&
              <div style={{ display: 'flex', justifyContent: 'end', width: '80%', marginBottom: -4 }}>
                Optional:
              </div>}

            <div style={{ display: 'flex', width: talspiegel && stundennachgabe ? '100%':'50%', flexDirection: 'row', justifyContent: 'space-evenly' }}>
              <div
                className='weiterbutton'
                style={{ display: 'flex',  }}
                onClick={() => {
                  setScreen(1)
                }}> {language ? 'Back' : 'Zurück'} </div>


              {talspiegel && stundennachgabe && <div
                className='weiterbutton'
                style={option ? { backgroundColor: 'rgba(83, 158, 123, 0.664)', } : {}}
                onClick={() => {
                  //setStatus(os => { return os + ' + optionale Einzeldosis' })
                  setOption(!option)
                }}>  {language ? 'alternate dose' : 'Andere Dosierung'} </div>}
            </div>

          </div>
        }

{(screen === 2) && medikation == 0 && option && gender != null && gewicht && alter && groesse && //TODO: Dosisgrenzen unklar (Tabelle anders als DOC) es gilt Worddokument 
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: '336px',
                marginTop: '8px',
                backgroundColor: '#282c34',
                fontFamily: 'Inter', fontSize: SCHRIFTGROSS,
                color: 'white',


              }}>


                <Eingabe titel={language ? 'Alternate dose' : 'Neue geplante Einzeldosis'} optionArray={['250-4000']} setVariable={setNeueeinzeldosis} variable={neueeinzeldosis} min={250} max={4000} unit={['mg']} faktor={[1]}></Eingabe>
                <Eingabe titel={language ? 'Alternate dosing interval' : 'Neues Dosisintervall'} optionArray={['4-72']} setVariable={setNeuesdosisintervall} variable={neuesdosisintervall} min={4} max={72} unit={['h']} faktor={[1]}></Eingabe>

                {neueeinzeldosis && neuesdosisintervall &&
                  <InfoDreiNeu titel={''}
                    infos={[language ? 'Alternate AUC 24h' : 'Neue AUC 24h', language ? 'Alternate trough level ' : 'Neuer Talspiegel', language ? 'Alternate peak level' : 'Neuer Spitzenspiegel']}
                    ergebnisse={[computeNeueAUC24(), computeNeuerTalspiegel(), computeNeuerSpitzenspiegel()]}
                    units={['mg*h/l', 'mg/l', 'mg/l']} ></InfoDreiNeu>}



              </div>
            }

        {(screen === 2) && medikation == 1 && gender != null && gewicht && alter && groesse &&
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '336px',
            marginTop: '8px',
            backgroundColor: '#282c34',
            fontFamily: 'Inter', fontSize: SCHRIFTGROSS,
            color: 'white',

          }}>

            <Eingabe titel={language ? 'Blood level' : 'Serumspiegel'} optionArray={['2-100']} setVariable={setSerumspiegel} variable={serumspiegel} min={2} max={100} decimals={1} unit={['mg/l']} faktor={[1]}></Eingabe>
            <Auswahl titel={''} optionArray={[language ? 'Dose' : 'Dosis', language ? 'Infusion rate' : 'Laufrate']} setVariable={setDosrate} variable={dosrate}></Auswahl>

            {dosrate == 0 &&
              <Eingabe titel={language ? 'Dose' : 'Infusionsdosis'} optionArray={['250-8000']} setVariable={setInfusionsdosis} variable={infusionsdosis} min={250} max={8000} unit={['mg/24h']} faktor={[1]}></Eingabe>}
            {dosrate == 101 && infusionsdosis &&
              <Ergebnis titel={language ? 'Comp. Infusion rate' : 'Berechnete Laufrate'} ergebnis={computeLaufrate(infusionsdosis)} unit={'ml/h'} setVariable={setLaufrate} variable={laufrate}></Ergebnis>}

            {dosrate == 1 &&
              <Eingabe titel={language ? 'Infusion rate' : 'Laufrate'} optionArray={['4']} setVariable={setLaufrate} variable={laufrate} decimals={2} unit={['ml/h']} min={1} max={11} faktor={[1]}></Eingabe>}
            {dosrate == 111 && laufrate &&
              <Ergebnis titel={language ? 'Computed dose' : 'Berechnete Dosis'} ergebnis={computeDosis()} unit={'mg/24h'} setVariable={setInfusionsdosis} variable={infusionsdosis}></Ergebnis>}

            {warnung && infusionsdosis && //talspiegel && stundennachgabe && dosis && dosisintervall &&

              <div style={{
                //position: 'absolute',  
                display: 'flex', flexDirection: 'column',
                alignItems: 'center',
                padding: PADDINGGROSS,
                margin: MARGINKLEIN,
                borderRadius: 10,
                color: 'white', backgroundColor: '#A72134'
              }}>
                <h2>
                  {language ? 'WARNING' : 'WARNHINWEIS'}
                </h2>

                {dosrate == 0 && (((infusionsdosis / INDvancomycinClearanceDauerinfusion) < 400) || (infusionsdosis / INDvancomycinClearanceDauerinfusion) > 600) &&
                  <p>{language ? 'Dosing outside target range' : 'AUC nicht im angestrebten Bereich'} </p>
                }

                {dosrate == 1 && ((infusionsdosis / INDvancomycinClearanceDauerinfusion < 400) || (infusionsdosis / INDvancomycinClearanceDauerinfusion) > 600) &&
                  <p>{language ? 'Dosing outside target range' : 'AUC nicht im angestrebten Bereich'} </p>
                }

                {((INDvancomycinClearanceDauerinfusion < vancomycinCl * 0.75) || (INDvancomycinClearanceDauerinfusion > vancomycinCl * 1.5)) &&
                  <div style={{ fontFamily: 'Inter', fontSize: SCHRIFTKLEIN }}>
                    <p>{language ? 'Dose administered?' : 'Dosis gegeben?'}</p>
                    <p>{language ? 'Dose omitted?' : 'Dosis vergessen?'}</p>
                    <p>{language ? 'Time of blood sampling?' : 'Zeitpunkt der Blutabnahme?'}</p>
                    <p>{language ? 'Sample take from access?' : 'Abnahme aus Zugang?'}</p>
                    <p>{language ? 'Right patient?' : 'Patient verwechselt?'}</p>
                  </div>
                }



                <div
                  className='weiterbutton'
                  onClick={() => {
                    setWarnung(false)
                  }}> OK </div>


              </div>}

            {dosrate == 0 && infusionsdosis && laufrate &&
              <InfoDrei titel={language ? 'Alternate dose' : 'Neue Dosierung'}
                infos={[' AUC 400', language ? 'Dose' : 'Dosis', 'AUC 600']}
                ergebnisse={[Math.round(INDvancomycinClearanceDauerinfusion * 400), Math.round(INDvancomycinClearanceDauerinfusion * 500), Math.round(INDvancomycinClearanceDauerinfusion * 600)]}
                units={['mg/24h', 'mg/24h', 'mg/24h']} ></InfoDrei>}

            {dosrate == 1 && infusionsdosis && laufrate &&
              <InfoDrei titel={language ? 'Alternate dose' : 'Neue Dosierung'}
                infos={[' AUC 400', language ? 'Dose' : 'Dosis', 'AUC 600']}
                ergebnisse={[Math.round(INDvancomycinClearanceDauerinfusion * 400), Math.round(INDvancomycinClearanceDauerinfusion * 500), Math.round(INDvancomycinClearanceDauerinfusion * 600)]}
                units={['mg/24h', 'mg/24h', 'mg/24h']} ></InfoDrei>}


            {infusionsdosis && laufrate &&
              <InfoDrei titel={language ? 'New infusion rate' : 'Neue Laufrate'}
                infos={[' AUC 400', language ? 'Infusion rate' : 'Laufrate', 'AUC 600']}
                ergebnisse={[computeLaufrate(INDvancomycinClearanceDauerinfusion * 400), computeLaufrate(INDvancomycinClearanceDauerinfusion * 500), computeLaufrate(INDvancomycinClearanceDauerinfusion * 600)]}
                units={['ml/h', 'ml/h', 'ml/h']} ></InfoDrei>}


            <div style={{ display: 'flex', width:'50%', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 30 }}>
              <div
                className='weiterbutton'
                onClick={() => {
                  setScreen(1)
                }}> {language ? 'Back' : 'Zurück'} </div>
            </div>

          </div>
        }

        {screen == 2 && medikation == 1 && vancospiegel == 0 &&
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '336px',
            marginTop: '8px',
            backgroundColor: '#282c34',
            fontFamily: 'Inter', fontSize: SCHRIFTGROSS,
            color: 'white',

          }}>



            <Info titel={language ? 'Population data' : 'Populationsparameter'}
              infos={[language ? 'Volume of distribution ' : 'Verteilungsvolumen', language ? 'Half-life ' : 'Halbwertszeit', 'Vancomycin Cl']}
              ergebnisse={[verteilungsvolumen, halbwertszeit, vancomycinClearance]}
              units={['l/kg', 'h', 'l/h']} ></Info>

            <Info titel={language ? 'Estimated individual pharmacokinetic parameters' : 'Individuelle kinetische Erwartungswerte'}
              infos={[language ? 'Volume of distribution ' : 'Verteilungsvolumen', 'Vancomycin Cl', language ? 'Half-life ' : 'Halbwertszeit']}
              ergebnisse={[Math.round(INDverteilungsvolumen * 10) / 10, Math.round(vancomycinCl * 10) / 10, Math.round(vancomycinhalbwertszeit * 10) / 10]}
              units={['l', 'l/h', 'h']} ></Info>

            {dosrate == 0 && infusionsdosis && laufrate &&
              <Info titel={language ? 'Estimated individual pharmacokinetic parameters' : 'Individuelle kinetische Parameter'}
                infos={['AUC 24 h', 'Vancomycin Cl']}
                warnungCl={((INDvancomycinClearanceDauerinfusion < vancomycinCl * 0.75) || (INDvancomycinClearanceDauerinfusion > vancomycinCl * 1.5))} //STIMMT MIT TABELLE ÜBEREIN
                warnungauc={(((infusionsdosis / INDvancomycinClearanceDauerinfusion) < 400) || (infusionsdosis / INDvancomycinClearanceDauerinfusion) > 600)}
                ergebnisse={[computeNeueAUC24Dauerinfusion(), INDvancomycinClearanceDauerinfusion.toFixed(2)]}
                units={['mg*h/l', 'l/h']} ></Info>}



            {dosrate == 1 && infusionsdosis && laufrate &&
              <Info titel={language ? 'Estimated individual pharmacokinetic parameters' : 'Individuelle kinetische Parameter'}
                infos={['AUC 24 h', 'Vancomycin Cl']}
                warnungCl={((INDvancomycinClearanceDauerinfusion < vancomycinCl * 0.75) || (INDvancomycinClearanceDauerinfusion > vancomycinCl * 1.5))} //STIMMT MIT TABELLE ÜBEREIN
                warnungauc={(((infusionsdosis / INDvancomycinClearanceDauerinfusion) < 400) || (infusionsdosis / INDvancomycinClearanceDauerinfusion) > 600)}
                ergebnisse={[computeNeueAUC24Dauerinfusion(), Math.round((laufrate * 1000 / (2.1 * 24)) / serumspiegel * 10) / 10]}
                units={['mg*h/l', 'l/h']} ></Info>}

          </div>
        }


        {screen == 2 && medikation == 0 && vancospiegel == 0 && talspiegel && stundennachgabe && dosis && dosisintervall &&
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '336px',
            marginTop: '8px',
            backgroundColor: '#282c34',
            fontFamily: 'Inter', fontSize: SCHRIFTGROSS,
            color: 'white',
          }}>



            {talspiegel && stundennachgabe && dosis && dosisintervall &&
              <Info titel={language ? 'Population data' : 'Populationsparameter'}
                infos={[language ? 'Volume of distribution' : 'Verteilungsvolumen', language ? 'Half-life ' : 'Halbwertszeit', 'Vancomycin Cl']}
                ergebnisse={[verteilungsvolumen, halbwertszeit, vancomycinClearance]}
                units={['l/kg', 'h', 'l/h']} ></Info>}


            <Info titel={language ? 'Estimated individual pharmacokinetic parameters' : 'Individuelle kinetische Erwartungswerte'}
              infos={[language ? 'Volume of distribution ' : 'Verteilungsvolumen', 'Vancomycin Cl', language ? 'Half-life ' : 'Halbwertszeit']}
              ergebnisse={[Math.round(INDverteilungsvolumen * 10) / 10, Math.round(vancomycinCl * 10) / 10, Math.round(vancomycinhalbwertszeit * 10) / 10]}
              units={['l', 'l/h', 'h']} ></Info>


            {talspiegel && stundennachgabe && dosis && dosisintervall &&
              <Info titel={language ? 'Estimated individual pharmacokinetic parameters' : 'Individuelle kinetische Parameter'}
                infos={['AUC 24h', 'Vancomycin Cl', language ? 'Elimination constant' : 'Eliminationskonstante']}
                ergebnisse={[Math.round(((dosis * 24 / dosisintervall) / INDvancomycinClearance) * 1) / 1, Math.round(INDvancomycinClearance * 10) / 10, Math.round(eliminationskonstante * 1000) / 1000]}
                units={['mg*h/l', 'l/h', '1/h']}
                warnungCl={((INDvancomycinClearance < vancomycinCl * 0.75) || (INDvancomycinClearance > vancomycinCl * 1.5))}
                warnungauc={((((dosis * 24 / dosisintervall) / INDvancomycinClearance) < 400) || ((dosis * 24 / dosisintervall) / INDvancomycinClearance) > 600)}
                zusatzinfos={[language ? 'Trough level' : 'Talspiegel', language ? 'Peak level' : 'Spitzenspiegel', language ? 'Half-life ' : 'Halbwertszeit']}
                zusatzergebnisse={[Math.round(INDtalspiegel * 10) / 10, Math.round(spitzenspiegel * 10) / 10, Math.round(INDhalbwertszeit * 10) / 10]}
                zusatzunits={['mg/l', 'mg/l', 'h']} ></Info>}





          </div>}




        {screen == 4 &&
          <div style={{ width: '50%', marginLeft: '10%', color: 'white', alignItems: 'center' }}>


            <h2 >
              {language ? 'About VancoEasy' : 'Über VancoEasy'}
            </h2>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
              <p style={{ width: window.innerWidth / 1.2 }}>
                {language ? 'VancoEasy is a training software developed for physicians and pharmacists to estimate influences of vancomycin regimens and to understand pharmacokinetic formulas. This project was supported by the ADKA e.V.  VancoEasy uses pharmacokinetic population estimates, the Dettli method [1,2] and the Sawchuk-Zaske method [1] to calculate vancomycin dosing regimen for an adult patient. Vancomycin regimens can be calculated both empirically (without any prior doses) or using vancomycin levels in steady-state. VancoEasy determines pharmacokinetic parameters and vancomycin dosing strategies using the following steps:' : 'VancoEasy ist eine Lernsoftware entwickelt mit Unterstützung der ADKA e.V. für die Ausbildung von Ärzten und Apothekern, um Einflüsse der Vancomycin-Dosierung abzuschätzen und pharmakokinetische Formeln zu verstehen. VancoEasy verwendet zur Berechnung der Vancomycin-Dosierung pharmakokinetische Populationsschätzungen, die Dettli-Methode [1,2] und die Sawchuk-Zaske-Methode [1]. Vancomycin-Dosierungen können sowohl empirisch (ohne vorherige Dosierung) als auch unter Verwendung von einem Vancomycin-Spiegel berechnet werden. VancoEasy ermittelt pharmakokinetische Parameter und Vancomycin-Dosierungsstrategien anhand der folgenden Schritte:'}

                {language ? <h4>
                  1. Estimate Cl and Vd
                </h4> : <h4>
                  1. Abschätzung von Cl und Vd
                </h4>}

                {language ? 'Empiric dosing (no drug levels): CL and Vd are determined using population estimates from published literature (Vd =0.7 L/kg, CLpop = 5.0 L/h) [1]. Vd is assumed considering actual body weight. Cl is assumed considering renal function. Using the Cockcroft-Gault equation [1,3], calculate creatinine clearance using ideal body weight (IBW):' : 'Empirische Dosierung (kein Spiegel): Cl und Vd werden über Populationsdaten aus der Literatur bestimmt               (Vd =0,7 L/kg, CLpop = 5,0 L/h) [1].   Vd wird unter Berücksichtigung des aktuellen Körpergewichts abgeschätzt. Cl wird unter Berücksichtigung der Nierenfunktion abgeschätzt. Die Kreatinin-Clearance (CrCl) wird dafür über Cockcroft Gault [1,3] mit dem idealen Körpergewicht (IKG) berechnet.'}
                <h5>
                  {!language ? <img src={formel1} style={{ margin: MARGINKLEIN, width: '60%', maxWidth: 200 }} /> : <img src={formel11} style={{ margin: MARGINKLEIN, width: '60%', maxWidth: 200 }} />}
                </h5>

                <h5>
                  {!language ? <img src={formel2} style={{ margin: MARGINKLEIN, width: '60%', maxWidth: 200 }} /> : <img src={formel12} style={{ margin: MARGINKLEIN, width: '60%', maxWidth: 200 }} />}
                </h5>
                {language ? 'Determine the dose adjustment factor Q by the ratio of the patient’s eGFR to a presumed normal eGFR of 100 ml/min based on estimation of the minimal elimination rate fraction in subjects with normal renal function (Q0 = 0.13) [1,4], calculate Cl:' : 'Die Cl wird dann nach Dettli über die individuelle Ausscheidungskapazität Q (Q0 = 0.13) [1,4] berechnet:'}
                <h5>

                  {!language ? <img src={formel3} style={{ margin: MARGINKLEIN, width: '60%', maxWidth: 200 }} /> : <img src={formel13} style={{ margin: MARGINKLEIN, width: '60%', maxWidth: 200 }} />}
                </h5>

                <h5>   {!language ? <img src={formel4} style={{ margin: MARGINKLEIN, width: '50%', maxWidth: 100 }} /> : <img src={formel14} style={{ margin: MARGINKLEIN, width: '50%', maxWidth: 100 }} />}</h5>


                {language ? 'One drug level available (trough): Vd is assumed using a population estimate considering actual body weight. Clearance is then determined extrapolating a peak value, calculate ke:' : 'Talspiegel vorhanden: Vd wird unter Berücksichtigung des aktuellen Körpergewichts abgeschätzt. Cl wird dann über die Extrapolation des Spitzenspiegels und die Berechnung von ke bestimmt.'}
                <h5>
                  {!language ? <img src={formel5} style={{ margin: MARGINKLEIN, width: '60%', maxWidth: 200 }} /> : <img src={formel15} style={{ margin: MARGINKLEIN, width: '60%', maxWidth: 200 }} />}
                </h5>
                <h5>
                  {!language ? <img src={formel6} style={{ margin: MARGINKLEIN, width: '50%', maxWidth: 100 }} /> : <img src={formel16} style={{ margin: MARGINKLEIN, width: '50%', maxWidth: 100 }} />}
                </h5>










                <h4>
                  {language ? '2. Loading dose' : ' 2. Initialdosis'}
                </h4>
                {language ? 'A loading dose be considered in order to achieve rapid attainment of serum concentrations (target concentration 25 mg/L) [1,5]. Using a population estimate, calculate dose:' : 'Um ein schnelles Erreichen der Serumkonzentration zu erreichen, sollte eine Initialdosis in Betracht gezogen werden. Das Konzentrationsziel beträgt 25 mg/L [1,5]. Die Dosis wird mit Vd über Populationsdaten berechnet.'}
                <h5>
                  {!language ? <img src={formel7} style={{ margin: MARGINKLEIN, width: '50%', maxWidth: 200 }} /> : <img src={formel17} style={{ margin: MARGINKLEIN, width: '50%', maxWidth: 200 }} />}
                </h5>
                <h4>
                  {language ? '3. Use one-compartment PK equations to estimate peak, trough, and AUC' : '3. Ein-Kompartiment-Modelle zur Abschätzung von Talspiegel, Spitzenspiegel und AUC '}
                </h4>


                {language ? 'Once Cl, Vd, and Ke are determined, one-compartment pharmacokinetic equations can be used to determine the peak, trough, and AUC for a given regimen:' : 'Wenn Cl, Vd und Ke bestimmt sind, können anhand der Formeln für 1-Kompartiment-Modelle Talspiegel, Spitzenspiegel und Tagesdosis für eine Dosisstrategie bestimmt werden.'}
                <h5>
                  {!language ? <img src={formel8} style={{ margin: MARGINKLEIN, width: '60%', maxWidth: 200 }} /> : <img src={formel18} style={{ margin: MARGINKLEIN, width: '60%', maxWidth: 200 }} />}

                </h5>
                <h5>
                  {!language ? <img src={formel9} style={{ margin: MARGINKLEIN, width: '60%', maxWidth: 200 }} /> : <img src={formel19} style={{ margin: MARGINKLEIN, width: '60%', maxWidth: 200 }} />}

                </h5>
                <h5>

                  {!language ? <img src={formel10} style={{ margin: MARGINKLEIN, width: '60%', maxWidth: 200 }} /> : <img src={formel20} style={{ margin: MARGINKLEIN, width: '60%', maxWidth: 200 }} />}
                </h5>

                {language ? 'The calculated AUC goal is 500 mg*h/L [5]. All calculations assume MIC =1 mg/L [5,6]. “Empiric dosing” is limited to a loading dose of 2000 mg [4], maintenance doses are limited to at least 500 mg/dose for intermittent infusion. The minimum dosing interval is q6h for intermittent infusion. New or adjusted dosing for intermittent infusion are calculated using infusion rate of 1000 mg/h [4]. “Dosing outside of target range” may appear if the computed dose has an AUC under 400 or greater than 600 [5]. The “alternate dosing” calculator is user-driven and has no dosing or frequency limitations. ' : ' Das berechnete AUC-Ziel ist 500 mg*h/L [5]. Alle Berechnungen gehen von MIC =1 mg/L [5,6] aus. Die "empirische Dosierung" ist auf eine Initialdosis von 2000 mg [4] begrenzt, die Erhaltungsdosen sind bei intermittierender Infusion mindestens 500 mg/Dosis und das Dosierungsintervall mindestens 6h. Neue oder angepasste Dosierungen für die intermittierende Infusion werden mit einer Infusionsrate von 1000 mg/h [4] berechnet. „AUC nicht im angestrebten Bereich“ kann auftreten, wenn die berechnete Dosis eine AUC von weniger als 400 oder mehr als 600 aufweist [5]. Die Berechnung einer „Anderen Dosierung“ ist benutzergesteuert und hat keine Dosis- oder Dosisintervalleinschränkungen.'}
                <h4>
                  {language ? 'Inappropriate Populations for this calculator: ' : ' Ungeeignete Patientenpopulationen für diese Berechnungen:'}
                </h4>
                {language ? 'This calculator is NOT appropriate for the following patient populations: ' : 'Die verwendeten pharmakokinetischen Formeln sind für die folgenden Patientenpopulationen NICHT geeignet:'}


                {language ? 'Renal replacement therapy (see dosing www.thecaddy.de instead), Unstable renal function, Vancomycin MIC ≥ 2 mg/L, Pediatrics (< 18 years), Cystic fibrosis, Severe burn injury' : 'Nierenersatzverfahren (Berechnung der Erhaltungsdosis mit www.thecaddy.de möglich), Instabile Nierenfunktion, Vancomycin-MIC ≥ 2 mg/l, Pädiatrie (unter 18 Jahre), Mukoviszidose, Schwere Verbrennungsverletzung'

                }
                <h4>
                  {language ? 'Disclaimer:' : 'Haftungsausschluss:'}
                </h4>

                {language ? ' VancoEasy has been created for personal use only. The use of any result generated by VancoEasy is in any case the sole risk and responsibility of the Vancomycin calculator user. Therapeutic decision should not solely rely on VancoEasy as information provided by VancoEasy does not replace clinical judgement. Users should seek a doctor’s advice in addition to using this app and before making any medical decisions. Users should seek a doctor’s advice in addition to using this app and before making any medical decisions. Although the VancoEasy has been validated carefully, there is no guarantee for the accuracy of the provided results. This app is provided by Dr Ute Chiriac, Heidelberg University Hospital & Dr Otto Frey, Heidenheim Hospital. Before continuing, please make sure you have read and understood the disclaimer.' : ' VancoEasy wurde nur für den persönlichen Gebrauch erstellt. Die Verwendung eines von VancoEasy erzeugten Ergebnisses erfolgt in jedem Fall auf alleiniges Risiko und Verantwortung des VancoEasy-Benutzers. Therapeutische Entscheidungen sollten nicht ausschließlich auf VancoEasy beruhen, da die von VancoEasy bereitgestellten Informationen die klinische Beurteilung nicht ersetzen. VancoEasy-Benutzer sollten zusätzlich zur Verwendung dieser App und vor medizinischen Entscheidungen einen Arzt konsultieren. Obwohl VancoEasy sorgfältig validiert wurde, gibt es keine Garantie für die Richtigkeit der bereitgestellten Ergebnisse. Diese App wird bereitgestellt von Dr. Ute Chiriac, Universitätsklinikum Heidelberg & Dr. Otto Frey, Klinikum Heidenheim. Bevor Sie fortfahren, vergewissern Sie sich bitte, dass Sie den Haftungsausschluss gelesen und verstanden haben.'}

                <h4>
                  {language ? 'Literature:' : 'Literatur'}
                </h4>

                <p>
                  {'[1] Murphy JE.  Clinical pharmacokinetics. 7th Ed. American Society of Health Pharmacists (ASHP), 2021.'}

                </p>

                <p>
                  {'[2] Dettli L. The kidney in pre-clinical and clinical pharmacokinetics. Jpn J Clin Pharmacol Ther 1984;15: 241-54'}

                </p>

                <p>
                  {'[3] Cockcroft DW, Gault MH. Prediction of creatinine clearance from serum creatinine. Nephron 1976; 16: 31–41.'}

                </p>

                <p>
                  {language ? '[4] Summary of product characteristics Vancomycin CP 1.0 g®, Germany: December 2020. ' : '[4] Fachinformation Vancomycin CP 1.0 g®, Deutschland. Stand: Dezember 2020.'}

                </p>
                <p>
                  {'[5] Rybak MJ, Le J, Lodise TP, et al. Therapeutic monitoring of vancomycin for serious methicillin-resistant Staphylococcus aureus infections: A revised consensus guideline and review of the American Society of Health-System Pharmacists, the Infectious Diseases Society of America, the Pediatric Infectious Diseases Society and the Society of Infectious Diseases Pharmacists. Accessed April 2020. Available online at IDsociety.org.'}

                </p>
                <p>
                  {'[6] EUCAST. Antimicrobial wild type distributions of microorganisms for Vancomycin. Available online at mic.eucast.org.'}

                </p>
              </p>
            </div>

          </div>
        }


      </div>








    </div >
  );
}

export default App;
